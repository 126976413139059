export const extractDateString = (
  date: Date | string | null
): string | null => {
  if (date === null) {
    return null;
  }
  if (date instanceof Date) {
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
  }

  const re = /(?<year>\d{4}).(?<month>\d+).(?<day>\d+)/;
  const match = re.exec(date.toString());

  return match
    ? match.groups?.day + "." + match.groups?.month + "." + match.groups?.year
    : null;
};
